/*Globals*/
@media (min-width: 1200px) {
    .container{
        max-width: 1640px;
    }
}
@media (max-width: 1200px) {
  body main {
    padding-bottom:6em!important;
  }
}
@media (min-width: 1200px) {
  .fixed-bottom {
    position:relative!important;
  }
}
body {
  background:#f5f5f5;
  font-family: 'Raleway', sans-serif;
  font-size:16px;
}
body main {
  padding-bottom:2em;
}
h1,h2,h3,h4,h5,h6 {
  font-weight:700;
}
.center-this {
  position:absolute;
  left:50%;
  top:50%;
  transform:translate(-50%,-50%);
}
.hidden {
  display:none;
  visibility:hidden;
  pointer-events:none;
}

/*Buttons*/
.btn {
  border-radius:3px;
  font-weight:600;
  display:inline;
  font-size:0.8em;
}
.btn-lg {
  padding-top:1em;
  padding-bottom:1em;
  font-size:1em;
}
.btn-next{
  background-color:#DD024B;
  color:#fff;
  text-align: left;
}
.btn-next i {
  float:right;
  font-size:1.6em;
  line-height:1em;
}
.btn-next:hover{
  background-color:#DD024B;
  color:#fff;
  opacity:0.8;
}
.btn-choose {
  background-color:#F4AB11;
  color:#332F2F;
  width:100%;
  text-align: left;
}
.btn-choose:hover{
  background-color:#F4AB11;
  color:#332F2F;
  opacity:0.8;
}
.btn-choose i {
  float:right;
  font-size:1.6em;
  line-height:1em;
}
.btn-back{
  background-color:#F4AB11;
  color:#332F2F;
  text-align: right;
}
.btn-back:hover{
  background-color:#F4AB11;
  color:#332F2F;
  opacity:0.8;
}
.btn-back i {
  float:left;
  font-size:1.6em;
  line-height:1em;
}
.btn-confirm{
  background-color:#00B123;
  color:#fff;
  text-align: left;
}
.btn-confirm:hover{
  background-color:#00B123;
  color:#fff;
  opacity:0.8;
}
.btn-confirm i {
  float:right;
  font-size:1.6em;
  line-height:1em;
}
.btn-disable{
  background-color:#D8D8D8!important;
  color:#fff;
  pointer-events:visible;
  cursor:not-allowed!important;
}
.btn-disable:hover{
  background-color:#D8D8D8!important;
  color:#fff;
  opacity:1;
}
.btn.hidden {
  height:0;
  width:0;
  display:none;
}
/*Header*/
header.jumbotron-fluid {
  background:#ffffff;
  padding:1.5em 0;
  min-height:100px;
}
header.jumbotron-fluid .container h1{
  border-bottom:2px solid #F4AB11;
  font-size:1.2em;
  line-height:1.2em;
  margin:0;
  text-align: left;
  width:100%;
  font-weight:700;
  display:inline-block;
  padding-top: 1.2em;
}
.logo {
  width:100%;
  text-align:center;
  display:block;
  max-height:83px;
  object-fit: contain;
  object-position: center left;
}
/*Stage counter*/
.stages {
  margin:0;
  text-align: right;
  width:100%;
}
.stages ul {
  margin:0;
  padding:1.2em 0;
}
.stages ul li {
  list-style:none;
  width:2em;
  height:2em;
  display:inline-block;
  border:2px #707070 solid;
  border-radius:100%;
  text-align:center;
  line-height:1.5em;
  font-weight:600;
}
.stages ul li.done {
  background:#00B123;
  color:#fff;
}
.stages ul li.active {
  background:#F4AB11;
  color:#fff;
}
/* Responsive header tweaks */
  /* Extra Small devices (iPhone 4/5 phones, 576px and up) */
  @media (min-width: 320px) {
    header .logo {
      max-width:200px;
      text-align: center;
    }
    header h1,
    header .stages ul li {
      font-size:0.8em!important;
    }
  }
  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    header .logo {margin-top:0.7em}
    header h1{
      padding-top:1.6em!important;
      font-size:0.8em!important;
    }
    header .stages ul li {
      font-size:0.5em!important;
    }
  }
  /* Medium devices (tablets, 768px and up[])*/
  @media (min-width: 768px) {
    header .logo {margin-top:0.8em;}
    header h1{
      padding-top:1.5em!important;
      font-size:1em!important;
    }
    header .stages ul li {
      font-size:1em!important;
    }
  }
  /* Large devices (desktops, 992px and up)*/
  @media (min-width: 992px) {
    header .logo {margin-top:0;}
    header h1{
      padding-top:1.2em!important;
      font-size:1.2em!important;
    }
    header .stages ul li {
      font-size:1em!important;
    }
  }
/*Products*/
.product-wrapper {
  margin-top:20px;
}
.product-wrapper.wash-bg {
  background:#ffffff;
}
.product-wrapper h3,
.product-wrapper p{
  width:100%;
}
.product {
  border:2px solid #332F2F;
  border-radius:7px;
  padding:0.5em;
  background:#ffffff;
  height:100%;
  position:relative;
  padding-bottom:6em;
}
.product img {
  width:100%;
  margin-bottom:1em;
  object-fit: cover;
  object-position: center;
  max-height:227px;
}
.product h4 {
  font-size:1.2em;
  color:#F4AB11;
  padding-bottom:1.5em;
}
.product ul {
  margin:0;
  padding:0;
}
.product ul li {
  font-weight:600;
  list-style:none;
  border-bottom:1px solid #D8D8D8;
  color:#D8D8D8;
  padding:0.3em 0;
}
.product ul li span{
  float:right;
}
.product ul li.disable {
  list-style:none;
  border-bottom:1px solid #D8D8D8;
  color:#D8D8D8;
}
.product .btn {
  position:absolute;
  bottom:0.5em;
  left:0.5em;
  right:0.5em;
  width:calc(100% - 1em);
}
.product.done {
  border-color:#00B123;
}
.product.done h4 {
  color:#00B123;
}
.product.done ul li {
  color:#332F2F;
}
.product.done ul li span{
  color:#00B123;
  float:right;
}

/*Options*/
.option-wrapper {
  margin-top:20px;
}
.option-wrapper.wash-bg {
  background:#ffffff;
}
.option-wrapper h3,
.option-wrapper p{
  width:100%;
}
.option {
  border:2px solid #332F2F;
  border-radius:7px;
  padding:0.5em;
  background:#ffffff;
  height:100%;
  position:relative;
  cursor:pointer;
}
.option i {
  position:absolute;
  top:0.2em;
  right:0.2em;
  font-size:2em;
  color:#00B123;
  display:none;
}
.option.chosen i {
  display:block;
}
.option svg {
  width:calc(100% + 1em);
  margin:2em 0em -0.5em -0.5em;
  height:auto;
  margin-top:2em;
}
.option h4 {
  font-size:1.2em;
  padding:0.2em 0;
}
.option.chosen {
  border-color:#00B123;
}
.option.chosen h4 {
  color:#00B123;
}
.option.chosen svg #arrow,
.option.chosen svg #arrow-1,
.option.chosen svg #arrow-2,
.option.chosen svg #arrow-3,
.option.chosen svg #circle,
.option.chosen svg #circle-1,
.option.chosen svg #circle-2 {
  fill:#00B123 !important;
}
.option-sill {
  text-align:center;
  display:flex;
  flex-wrap:wrap;
  flex-direction:column;
  justify-content: flex-end;
  align-items: center;
}
.option-sill svg {
  bottom:20px;
  max-width:90%;
}
.option-sill h4 {
  margin-top:1.5em;
}
/*footer nav*/
nav.fixed-bottom {
  padding:20px 0;
  background:rgba(245,245,245,0.9);
}
@media (max-width: 1200px) {
  nav.fixed-bottom{
    border-top: 2px solid #707070;
  }
}

/*Main IDs*/
#products {}
#direction-options {}
#in-out-options {}
#access-options {}
#sill-with-without-options {}
#sill-threshold-options {}
#confirm-screen {}
#final-screen {}

/* Products */
#products + nav.fixed-bottom .btn-back{
  display:none;
  pointer-events: none;
}
/* Confirm-screen*/
#confirm-screen h4,
#confirm-screen p {
  text-align: center;
}
#confirm-screen h4{
  margin-top:10vh;
}
#confirm-screen h4,
#confirm-screen .confirm-buttons{
  margin-bottom:2em;
}

#confirm-screen + nav.fixed-bottom {
  display:none;
  pointer-events: none;
}
/*final-screen*/
#final-screen h4,
#final-screen p {
  text-align: center;
}
#final-screen i{
  color:#00B123;
  font-size:10em;
  margin-top:10vh;
}
#final-screen h4{
  color:#00B123;
  margin-top:1em;
}
#final-screen h4{
  margin-bottom:1em;
}
#final-screen + nav.fixed-bottom {
  display:none;
  pointer-events: none;
}
